<template>
  <div>
    <v-tour :options="tourOptions" name="setting" :steps="steps"></v-tour>
    <div class="title-page mb-4">
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0505 7.5H3.5C2.11929 7.5 1 6.38071 1 5C1 3.61929 2.11929 2.5 3.5 2.5H13.0505M6.94949 18.5H16.5C17.8807 18.5 19 17.3807 19 16C19 14.6193 17.8807 13.5 16.5 13.5H6.94949M1 16C1 17.933 2.567 19.5 4.5 19.5C6.433 19.5 8 17.933 8 16C8 14.067 6.433 12.5 4.5 12.5C2.567 12.5 1 14.067 1 16ZM19 5C19 6.933 17.433 8.5 15.5 8.5C13.567 8.5 12 6.933 12 5C12 3.067 13.567 1.5 15.5 1.5C17.433 1.5 19 3.067 19 5Z"
          stroke="#31006F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h3>{{ $t("accountSettings") }}</h3>
    </div>
    <div class="content-setting">
      <div class="tabs">
        <div class="profile">
          <div class="img-profile">
            <img
              :src="user ? user.image : '@/assets/images/elements/user2.svg'"
              :alt="user ? `${user.first_name} ${user.last_name}` : 'Image Profile'"
              width="75"
              height="75"
              class="flex p-0 rounded-full"
              onerror="this.src='@/assets/images/elements/user2.svg'"
              loading="lazy"
            />

            <div class="info-profile">
              <h4>{{ client.name }}</h4>
              <h5>{{ $t("basicClient") }}</h5>
              <h6 @click="copyText('copyCodeInput')">
                <input ref="copyCodeInput" type="hidden" :value="client.code" />
                {{ $t("yourCode") }} :
                <span
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.5533 13.5007C12.7533 13.5007 13.5 12.7073 13.5 11.434V7.37401C13.5 6.11404 12.74 5.30737 11.5533 5.30737H8.7533V6.70735C8.7533 7.88735 7.7933 8.85401 6.60664 8.86068H5.30664V11.434C5.30664 12.7073 6.04664 13.5007 7.24663 13.5007H11.5533ZM4.30664 10.694L4.31997 11.694C4.42664 13.3607 5.59997 14.5007 7.24663 14.5007H11.5533C13.2866 14.5007 14.5 13.2407 14.5 11.434V7.37401C14.5 5.61404 13.3466 4.37404 11.6866 4.31404L10.6866 4.30737H8.32663C7.8533 4.30737 7.39997 4.50071 7.0733 4.84071L4.78664 7.22068C4.47331 7.54735 4.30664 7.97401 4.30664 8.42068V10.694Z"
                      fill="#31006F"
                    />
                    <path
                      opacity="0.4"
                      d="M2.5 6.05333V8.62667C2.5 9.85333 3.18667 10.6333 4.30667 10.6933L4.32 11.6933C2.62667 11.64 1.5 10.42 1.5 8.62667V5.62C1.5 5.18667 1.67333 4.74667 1.98 4.42L4.26667 2.04C4.60667 1.69333 5.06 1.5 5.52 1.5H8.75333C10.4067 1.5 11.58 2.64667 11.6867 4.31333L10.6867 4.30667C10.5867 3.2 9.85333 2.5 8.75333 2.5H5.95333V3.90667C5.95333 5.08667 4.99333 6.05333 3.80667 6.05333H2.5Z"
                      fill="#31006F"
                    />
                    </svg>
                    {{ client.code }}
                  </span
                >
              </h6>
            </div>
          </div>
          <div class="nav-profile">
            <ul>
              <router-link
                v-for="tab in allTabs"
                :key="tab.id"
                :to="tab.routerLink"
              >
                <li class="item" v-if="hasAccessToProccess(tab)">
                  <span
                    class="flex items-center"
                    @click="toggleItmes(tab), checkToggleItems(tab)"
                  >
                    <i v-html="tab.icon"></i>
                    {{ $t(tab.name) }}
                    <svg
                      v-if="tab.subTabs"
                      class="svg-arrow"
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1.25L6 5.75L10.5 1.25"
                        stroke="#666666"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <ul
                    :data-toggle="tab.toggleData"
                    v-if="tab.subTabs"
                    :ref="tab.refSubTabs"
                    :class="tab.mainClass"
                  >
                    <router-link
                      v-for="info in tab.subTabs"
                      :key="info.id"
                      :to="info.routerLink ? info.routerLink : ''"
                      @click.prevent.native="activeChildClass = info.id"
                      :class="
                        activeChildClass == info.id
                          ? 'active-child-item'
                          : 'child-item'
                      "
                      class="flex items-center"
                    >
                      {{ $t(info.name) }}
                      <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 13L1 7L7 1"
                          stroke="#666666"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </ul>
                </li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>

      <div class="content-of-tabs">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import copyCode from "@/mixins/helper.js";
import tour from "@/mixins/tour.js";
import tabs from "@/layouts/components/Tabs";
import tab from "@/layouts/components/Tab";
import aclAccess from "@/mixins/hasAccess";

export default {
  mixins: [copyCode, tour, aclAccess],
  components: {
    tabs,
    tab,
  },
  data() {
    return {
      active: false,
      activeChildClass: 1,
      steps: [
        {
          target: '[data-tour-step="3"]',
          header: {
            title: "البحث المتقدم",
          },
          content: `من هنا يمكنك البحث عن طرد`,
        },
        {
          target: '[data-setting-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "الربط API",
          },
          content: `من هنا يمكنك يمكنك الربط مع تربو ب API`,
          params: {
            enableScrolling: false,
          },
        },
      ],
      allTabsRefs: ["basicInfo", "linkWithApi"],
      allTabs: [
        {
          id: 1,
          name: "basicInfo",
          routerLink: "/setting-main-info",
          icon: `<svg
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 7.5V11.5M11 15.5H11.01M1 8.02274V14.9773C1 15.2218 1 15.3441 1.02763 15.4592C1.05213 15.5613 1.09253 15.6588 1.14736 15.7483C1.2092 15.8492 1.29568 15.9357 1.46863 16.1086L6.39137 21.0314C6.56432 21.2043 6.6508 21.2908 6.75172 21.3526C6.84119 21.4075 6.93873 21.4479 7.04077 21.4724C7.15586 21.5 7.27815 21.5 7.52274 21.5H14.4773C14.7218 21.5 14.8441 21.5 14.9592 21.4724C15.0613 21.4479 15.1588 21.4075 15.2483 21.3526C15.3492 21.2908 15.4357 21.2043 15.6086 21.0314L20.5314 16.1086C20.7043 15.9357 20.7908 15.8492 20.8526 15.7483C20.9075 15.6588 20.9479 15.5613 20.9724 15.4592C21 15.3441 21 15.2218 21 14.9773V8.02274C21 7.77815 21 7.65586 20.9724 7.54077C20.9479 7.43873 20.9075 7.34119 20.8526 7.25172C20.7908 7.1508 20.7043 7.06432 20.5314 6.89137L15.6086 1.96863C15.4357 1.79568 15.3492 1.7092 15.2483 1.64736C15.1588 1.59253 15.0613 1.55213 14.9592 1.52763C14.8441 1.5 14.7218 1.5 14.4773 1.5H7.52274C7.27815 1.5 7.15586 1.5 7.04077 1.52763C6.93873 1.55213 6.84119 1.59253 6.75172 1.64736C6.6508 1.7092 6.56432 1.79568 6.39137 1.96863L1.46863 6.89137C1.29568 7.06432 1.2092 7.1508 1.14736 7.25172C1.09253 7.34119 1.05213 7.43873 1.02763 7.54077C1 7.65586 1 7.77815 1 8.02274Z"
                        stroke="#666666"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>`,
          subTabs: [
            { id: 1, name: "customerInfo" },
            { id: 2, name: "shippingInfo" },
            { id: 3, name: "locationInfo" },
            { id: 4, name: "serviceInfo" },
          ],
          refSubTabs: "basicInfo",
          hasAccess: true,
          mainClass: "hidden-child-item",
          toggleData: "true",
        },
        {
          id: 2,
          name: "changePassword",
          routerLink: "/setting-change-password",
          icon: `<svg
                      width="22"
                      height="19"
                      viewBox="0 0 22 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 7.5V4.7C21 3.5799 21 3.01984 20.782 2.59202C20.5903 2.21569 20.2843 1.90973 19.908 1.71799C19.4802 1.5 18.9201 1.5 17.8 1.5H4.2C3.0799 1.5 2.51984 1.5 2.09202 1.71799C1.71569 1.90973 1.40973 2.21569 1.21799 2.59202C1 3.01984 1 3.5799 1 4.7V8.3C1 9.4201 1 9.98016 1.21799 10.408C1.40973 10.7843 1.71569 11.0903 2.09202 11.282C2.51984 11.5 3.0799 11.5 4.2 11.5H10M11 6.5H11.005M16 6.5H16.005M6 6.5H6.005M18.25 13.5V11.75C18.25 10.7835 17.4665 10 16.5 10C15.5335 10 14.75 10.7835 14.75 11.75V13.5M11.25 6.5C11.25 6.63807 11.1381 6.75 11 6.75C10.8619 6.75 10.75 6.63807 10.75 6.5C10.75 6.36193 10.8619 6.25 11 6.25C11.1381 6.25 11.25 6.36193 11.25 6.5ZM16.25 6.5C16.25 6.63807 16.1381 6.75 16 6.75C15.8619 6.75 15.75 6.63807 15.75 6.5C15.75 6.36193 15.8619 6.25 16 6.25C16.1381 6.25 16.25 6.36193 16.25 6.5ZM6.25 6.5C6.25 6.63807 6.13807 6.75 6 6.75C5.86193 6.75 5.75 6.63807 5.75 6.5C5.75 6.36193 5.86193 6.25 6 6.25C6.13807 6.25 6.25 6.36193 6.25 6.5ZM14.6 17.5H18.4C18.9601 17.5 19.2401 17.5 19.454 17.391C19.6422 17.2951 19.7951 17.1422 19.891 16.954C20 16.7401 20 16.4601 20 15.9V15.1C20 14.5399 20 14.2599 19.891 14.046C19.7951 13.8578 19.6422 13.7049 19.454 13.609C19.2401 13.5 18.9601 13.5 18.4 13.5H14.6C14.0399 13.5 13.7599 13.5 13.546 13.609C13.3578 13.7049 13.2049 13.8578 13.109 14.046C13 14.2599 13 14.5399 13 15.1V15.9C13 16.4601 13 16.7401 13.109 16.954C13.2049 17.1422 13.3578 17.2951 13.546 17.391C13.7599 17.5 14.0399 17.5 14.6 17.5Z"
                        stroke="#666666"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>`,
          subTabs: "",
          refSubTabs: "",
          hasAccess: true,
        },
        {
          id: 3,
          name: "subUser",
          routerLink: "/setting-employee-accounts",
          icon: `<svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.9998 14.3369C18.4557 15.0683 19.704 16.242 20.6151 17.7096C20.7955 18.0003 20.8857 18.1456 20.9169 18.3468C20.9803 18.7558 20.7006 19.2585 20.3198 19.4204C20.1323 19.5 19.9215 19.5 19.4998 19.5M14.9998 10.0322C16.4816 9.29589 17.4998 7.76686 17.4998 6C17.4998 4.23314 16.4816 2.70411 14.9998 1.96776M12.9998 6C12.9998 8.48528 10.9851 10.5 8.49984 10.5C6.01455 10.5 3.99984 8.48528 3.99984 6C3.99984 3.51472 6.01455 1.5 8.49984 1.5C10.9851 1.5 12.9998 3.51472 12.9998 6ZM1.55907 17.4383C3.15337 15.0446 5.66921 13.5 8.49984 13.5C11.3305 13.5 13.8463 15.0446 15.4406 17.4383C15.7899 17.9628 15.9645 18.225 15.9444 18.5599C15.9287 18.8207 15.7578 19.14 15.5494 19.2976C15.2818 19.5 14.9137 19.5 14.1775 19.5H2.82219C2.08601 19.5 1.71791 19.5 1.45028 19.2976C1.24189 19.14 1.07092 18.8207 1.05527 18.5599C1.03517 18.225 1.2098 17.9628 1.55907 17.4383Z"
                        stroke="#666666"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>`,
          subTabs: "",
          refSubTabs: "",
          hasAccess: true,
        },
        {
          id: 4,
          name: "notificationSetting",
          routerLink: "/control-notification",
          icon: `<svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.35442 21.5C10.0596 22.1224 10.9858 22.5 12.0002 22.5C13.0147 22.5 13.9409 22.1224 14.6461 21.5M2.29414 6.31989C2.27979 4.86854 3.06227 3.51325 4.32635 2.8M21.7024 6.3199C21.7167 4.86855 20.9342 3.51325 19.6702 2.8M18.0002 8.5C18.0002 6.9087 17.3681 5.38258 16.2429 4.25736C15.1177 3.13214 13.5915 2.5 12.0002 2.5C10.4089 2.5 8.88283 3.13214 7.75761 4.25736C6.63239 5.38258 6.00025 6.9087 6.00025 8.5C6.00025 11.5902 5.22072 13.706 4.34991 15.1054C3.61538 16.2859 3.24811 16.8761 3.26157 17.0408C3.27649 17.2231 3.31511 17.2926 3.46203 17.4016C3.59471 17.5 4.19284 17.5 5.3891 17.5H18.6114C19.8077 17.5 20.4058 17.5 20.5385 17.4016C20.6854 17.2926 20.724 17.2231 20.7389 17.0408C20.7524 16.8761 20.3851 16.2859 19.6506 15.1054C18.7798 13.706 18.0002 11.5902 18.0002 8.5Z"
                        stroke="#666666"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>`,
          subTabs: "",
          refSubTabs: "",
          hasAccess: true,
        },
        {
          id: 5,
          name: "linkWithPlugin",
          routerLink: "/link-with-plugins",
          icon: `<svg
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5 4C6.5 2.61929 7.61929 1.5 9 1.5C10.3807 1.5 11.5 2.61929 11.5 4V5.5H12.5C13.8978 5.5 14.5967 5.5 15.1481 5.72836C15.8831 6.03284 16.4672 6.61687 16.7716 7.35195C17 7.90326 17 8.60218 17 10H18.5C19.8807 10 21 11.1193 21 12.5C21 13.8807 19.8807 15 18.5 15H17V16.7C17 18.3802 17 19.2202 16.673 19.862C16.3854 20.4265 15.9265 20.8854 15.362 21.173C14.7202 21.5 13.8802 21.5 12.2 21.5H11.5V19.75C11.5 18.5074 10.4926 17.5 9.25 17.5C8.00736 17.5 7 18.5074 7 19.75V21.5H5.8C4.11984 21.5 3.27976 21.5 2.63803 21.173C2.07354 20.8854 1.6146 20.4265 1.32698 19.862C1 19.2202 1 18.3802 1 16.7V15H2.5C3.88071 15 5 13.8807 5 12.5C5 11.1193 3.88071 10 2.5 10H1C1 8.60218 1 7.90326 1.22836 7.35195C1.53284 6.61687 2.11687 6.03284 2.85195 5.72836C3.40326 5.5 4.10218 5.5 5.5 5.5H6.5V4Z"
                        stroke="#666666"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>`,
          subTabs: "",
          refSubTabs: "",
          hasAccess: true,
        },
        {
          id: 6,
          name: "linkingApi",
          routerLink: "/link-with-apis",
          icon: `<svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5708 20.5C19.8328 20.5 20.8568 19.477 20.8568 18.214V13.643L21.9998 12.5L20.8568 11.357V6.786C20.8568 5.523 19.8338 4.5 18.5708 4.5M5.429 4.5C4.166 4.5 3.143 5.523 3.143 6.786V11.357L2 12.5L3.143 13.643V18.214C3.143 19.477 4.166 20.5 5.429 20.5M7.5 12.5H7.51M12 12.5H12.01M16.5 12.5H16.51M8 12.5C8 12.7761 7.77614 13 7.5 13C7.22386 13 7 12.7761 7 12.5C7 12.2239 7.22386 12 7.5 12C7.77614 12 8 12.2239 8 12.5ZM12.5 12.5C12.5 12.7761 12.2761 13 12 13C11.7239 13 11.5 12.7761 11.5 12.5C11.5 12.2239 11.7239 12 12 12C12.2761 12 12.5 12.2239 12.5 12.5ZM17 12.5C17 12.7761 16.7761 13 16.5 13C16.2239 13 16 12.7761 16 12.5C16 12.2239 16.2239 12 16.5 12C16.7761 12 17 12.2239 17 12.5Z"
                        stroke="#666666"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>`,
          subTabs: [
            { id: 1, name: "orders", routerLink: "/link-with-apis/orders" },
            {
              id: 2,
              name: "ticketSupport",
              routerLink: "/link-with-apis/tickets",
            },
          ],
          refSubTabs: "linkWithApi",
          hasAccess: true,
          mainClass: "hidden-child-item",
          toggleData: "false",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("userData", ["client", "user"]),
  },
  methods: {
    toggleItmes(tab) {
      if (tab.refSubTabs) {
        let el = this.$refs[tab.refSubTabs][0];
        el.classList.value = "show-child-item";
        if (el.dataset.toggle == "false") {
          el.dataset.toggle = true;
          el.classList.value = "show-child-item";
        } else {
          el.dataset.toggle = false;
          el.classList.value = "hidden-child-item";
        }
      }
    },
    checkToggleItems(tab) {
      if (!tab.refSubTabs) {
        this.allTabsRefs.map((el) => {
          let ref = this.$refs[el][0];
          ref.dataset.toggle = false;
          ref.classList.value = "hidden-child-item";
        });
      } else {
        let ref = this.allTabs.filter(
          (activeTab) => activeTab.refSubTabs != tab.refSubTabs && activeTab.refSubTabs
        );
        let refSubTabs = ref[0].refSubTabs;
        ref = this.$refs[refSubTabs][0];
        ref.dataset.toggle = false;
        ref.classList.value = "hidden-child-item";
      }
    },
    hasAccessToProccess(tab) {
      if (
        tab.name == "linkingApi" ||
        tab.name == "linkWithPlugin" ||
        tab.name == "subUser"
      ) {
        if (this.hasAccess) return true;
        else return false;
      } else return true;
    },
  },
  mounted() {
    this.allTabs.map((el) => {
      if (el.routerLink == this.$route.matched[2].path) {
        if (el.refSubTabs) {
          let ref = this.$refs[el.refSubTabs][0];
          ref.dataset.toggle = false;
          ref.classList.value = "show-child-item";
        }
      }
    });
    if (this.runWalkthrough()) {
      // this.startTour("setting");
      setTimeout(() => {
        window.scrollTo(0, 20);
      }, 500);
      this.$watch(
        () => {
          return this.$tours.setting.currentStep;
        },
        (val) => {
          if (val == 1) {
            const nextStep = document.querySelector(".v-step__button");
            nextStep.addEventListener("click", () => {
              this.$router.push({ path: "/link-with-apis" });
            });
          }
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
</style>
<style lang="scss">
.router-link-active .item {
  span {
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}
</style>
